import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import './styles/test.css';
import Faq from "react-faq-component";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
  

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  // box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 20px;
  color: var(#000000);
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 400px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const sos = styled.div`
  margin-left: auto;
margin-right: 0;
`;

export const sos2 = styled.div`
align-items: end;
`;

export const Container2 = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const SocialMedia = styled.img`
  width: 50px;
  cursor: pointer;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  // background-color: var(--accent);
  //border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
   box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  //  background-color: var(--accent);
  //border-radius: 100%;
  width: 400px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StyledImg3 = styled.img`
  width: 400px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
    text-align: center;

`;




function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const data2 = {
    title: "FAQ",
    rows: [
      {
        title: "Why Foxy Foxes?",
        content: `Who doesn't like a cute fox (looking at you, furries)? We hope the community enjoys Foxy Foxes as much as we have enjoyed creating them.`,
      },
      {
        title: "What are the traits and attributes?",
        content:
          "Foxy Foxes have a diversity of over 120 traits and attributes, including full costumes, mouthpieces, background images, background colors, lower facewear, feetwear, nosewear, masks, neckwear, backwear, eyewear, bodywear, headwear, earwear, eye types, tail types, and skin types!",
      },
      {
        title: "What are the rarities?",
        content:
          "We want to keep it a surprise, so a full list of rarities will be disclosed after the public sale. Have no fear though, there are over 20 traits/attributes with a <2% chance to manifest and over 80 traits/attributes with a <5% chance to manifest! Keep an eye on our Twitter page and Discord server for sneak peeks.",
      },
      {
        title: "When is the mint?",
        content:
          "December 23rd at 9:00 PM EST",
      },
      {
        title: "What is the cost to mint?",
        content:
          "0.02 ETH.",
      },
      {
        title: "How many can I mint?",
        content:
          "Up to 2 per transaction, no limit per wallet.",
      },
      {
        title: "Can I mint from the contract?",
        content:
          "Yes! The contract address link will be provided at the bottom of our website.",
      },
      {
        title: "When is the reveal?",
        content:
          "Shortly after the public sale ends. Please keep in mind Opensea may take time to refresh the metadata. You can try speeding up this process manually by clicking the 'Refresh metadata' button located on your NFT page.",
      },
      {
        title: "What are the royalty fees?",
        content:
          "In order for our community to thrive, we have set our royalty fee to only 2.5%. Opensea also charges 2.5%, which would result in a total royalty fee of 5%.",
      },
      {
        title: "What wallets do you support?",
        content:
          "We officially support Metamask only.",
      },
      {
        title: "What token standard does Foxy Foxes use?",
        content:
          "ERC-721",
      },
      {
        title: "Where is the roadmap?",
        content:
          "No roadmap for now. We don't want to over promise and under deliver like most projects.",
      },
      {
        title: "Anything else?",
        content:
          "Please make sure you know how and when to mint (including how to win a gas war). Unfortunately, we will not be able to refund any transactions."
      },
    ],
  };

  const sos = {
    bgColor: '#0B1D51',
    titleTextColor: "#6daedb",
    titleTextSize: "56px",
    rowTitleColor: "#6daedb",
    rowTitleTextSize: "24px",
    rowContentColor: 'white',
    rowContentTextSize: '22px',
    arrowColor: "#6daedb",
    rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '10px',
    rowContentPaddingRight: '100px',
  };

  const config = {
    tabFocus: true
  };

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const timerProps = {
    isPlaying: true,
    size: 150,
    strokeWidth: 10
  };

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = new Date('22 Dec 2021 21:00:00 EST').getTime() / 1000;

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `CONGRATULATIONS! You have successfully minted your NFT! Visit Opensea.io to view your ${CONFIG.NFT_NAME}.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.SpacerLarge />



      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.Container flex={1} fd={"row"} jc={"center"} ai={"center"}>
          <StyledLogo alt={"logo"} src={"/config/images/logo3.png"} />
        </s.Container>

        <ResponsiveWrapper flex={1} style={{ padding: 70 }} test>
          <s.TextTitle2
            style={{
              textAlign: "center",
              fontSize: 50,
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            {data.totalSupply} / {CONFIG.MAX_SUPPLY} sold
            <s.SpacerXSmall />


          </s.TextTitle2>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/hidden.PNG"} />
          </s.Container>


          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 16,
                borderRadius: 24,
              }}
            >

              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
              </s.TextDescription>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Adopt one for {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}  + gas.
                    {/* <div className="App">
                      <CountdownCircleTimer
                        {...timerProps}
                          colors={[["#6DAEDB"]]}
                        duration={daysDuration}
                        initialRemainingTime={remainingTime}
                      >
                        {({ elapsedTime }) =>
                          renderTime("days", getTimeDays(daysDuration - elapsedTime))
                        }
                      </CountdownCircleTimer>
                        <s.SpacerMedium />

                      <CountdownCircleTimer
                        {...timerProps}
                          colors={[["#6DAEDB"]]}
                        duration={daySeconds}
                        initialRemainingTime={remainingTime % daySeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > hourSeconds
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime("hours", getTimeHours(daySeconds - elapsedTime))
                        }
                      </CountdownCircleTimer>
                        <s.SpacerMedium />

                      <CountdownCircleTimer
                        {...timerProps}
                          colors={[["#6DAEDB"]]}
                        duration={hourSeconds}
                        initialRemainingTime={remainingTime % hourSeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > minuteSeconds
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
                        }
                      </CountdownCircleTimer>
                        <s.SpacerMedium />

                      <CountdownCircleTimer
                        {...timerProps}
                          colors={[["#6DAEDB"]]}
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > 0
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime("seconds", getTimeSeconds(elapsedTime))
                        }
                      </CountdownCircleTimer>
                      </div> */}

                  </s.TextTitle>
                  <s.SpacerXSmall />

                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                      </s.TextDescription>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.Container>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>

      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />





      <div class="faq-section blackBg slanted-div" id="faq">
        <div class="container">
          <div class="faq-text-wrapper faq-row">
            <div class="container">
              <div class="col-12">
                <h3 class="welcome">WELCOME TO THE FOXY FOXES' DEN!</h3>
              </div>
              <s.SpacerMedium />
              <s.SpacerMedium />
              <div class="row align-items-center">
                <div class="col-12 col-md-6 order-md-2">
                  <StyledImg2
                    alt={"example"}
                    src={"/config/images/max-gif.gif"}
                  />
                </div>
                <div class="col-12 col-md-6 order-md-1">
                  <p class="faq-help">
                    Don't worry, we don't bite... hard. We are a pack of 8888 cute and cuddly foxes living in the burrows of the Ethereum blockchain. Each of us is one-of-a-kind and looking for a loving home. <br /> Will you adopt us?
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />


      <div class="faq-container">
        <Faq
          data={data2}
          styles={sos}
          config={config}
        />
      </div>


      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />
      <s.SpacerMedium />


      <div class="slanted-div2" >
        <div class="col-12">
          <h3 class="welcome">OUR TEAM</h3>
        </div>

        <s.Container flex={1} fd={"row"} jc={"center"} ai={"center"} style={{ padding: 100 }}>
          <s.Container flex={0} jc={"center"} ai={"center"}>
            <StyledImg3 alt={"example"} src={"/config/images/programmer.png"} />
            <s.TextTitle2>
              <StyledLink target={"_blank"} href="https://twitter.com/Sethsmagic">@Sethsmagic</StyledLink>
            </s.TextTitle2>
            <s.TextTitle3>
              PROGRAMMER
            </s.TextTitle3>
          </s.Container>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Container flex={0} jc={"center"} ai={"center"}>
            <StyledImg3 alt={"example"} src={"/config/images/artist.png"} style={{ transform: "scaleX(-1)" }} />
            <s.TextTitle2>Yuri</s.TextTitle2>
            <s.TextTitle3>
              ARTIST
            </s.TextTitle3>
          </s.Container>
        </s.Container>
      </div>

      <s.Container flex={0} fd={"row"} jc={"center"} ai={"center"} style={{ backgroundColor: "#000", padding: 5 }}>
        <SocialMedia onClick={(e) => {
          window.open("https://twitter.com/foxy_foxes")
        }} alt={"Twitter"} src={"/config/images/twitter.svg"} />
        <s.SpacerMedium />
        <SocialMedia onClick={(e) => {
          window.open("https://discord.gg/c63QUW2DGs")
        }} alt={"Discord"} src={"/config/images/discord.svg"} />
        <s.SpacerMedium />
        <SocialMedia onClick={(e) => {
          window.open("https://opensea.io/collection/foxy-foxes")
        }} alt={"Open Sea"} src={"/config/images/opensea.svg"} />
      </s.Container>
      <s.Container flex={0} fd={"row"} jc={"center"} ai={"center"} style={{ backgroundColor: "#000", padding: 15 }}>
        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} style={{ backgroundColor: "#000"}}>
        {(CONFIG.CONTRACT_ADDRESS)}
      </StyledLink>
      </s.Container>
      <s.Container flex={0} fd={"row"} jc={"center"} ai={"center"} style={{ backgroundColor: "#000" }}>
        <s.TextTitle4>
          ©2021 Foxy Foxes | All rights reserved.
        </s.TextTitle4>
      </s.Container>

    </s.Screen>
  );
}

export default App;